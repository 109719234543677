import Routes from '../consts/Routes';
import { VersionActions } from 'src/redux/actions/VersionActions';
import getStore from '../redux/getStore';

export class ApiError {
    public description: string | undefined;
    constructor(public title: string, public message?: string, public errorDetails?: any) {
    }
}

class ApiResultHandler {
    public static handle(response: Response): Response | Promise<Response> {
        if (response.ok || response.redirected) {
            let v = response.headers.get('api-version');
            if (v != null && $apiVersion !== v) {
                let store = getStore();
                if (store) {
                    store.dispatch(VersionActions.check($apiVersion, v));
                }
            }

            if (response.redirected) {
                window.location.href = response.url;
                let error = new ApiError('Redirected');
                throw error;
            }

            return response;
        } else if (response.status === 401 || response.status === 405) {
            // let url = history.location.pathname + history.location.search;
            // window.location.href = Routes.generate(Routes.LOGIN_ROUTE, {}, { returnUrl: url });
            window.location.href = Routes.generate(Routes.LOGIN_ROUTE, {});
            let error = new ApiError('Unauthorized');
            throw error;
        } else {
            let error = new ApiError(response.status + ' ' + response.statusText);
            let ct = response.headers.get('Content-Type');
            if (ct && ct.indexOf('/json') > 0) {
                return response.json().then((result: any) => {
                    if (result.message) {
                        error.message = result.message;
                    }
                    error.errorDetails = result;
                    error.description = (result || {}).exceptionMessage;
                    return Promise.reject(error);
                });
            } else {
                error.message += '\n\n\n {RESPONSE}: ' + JSON.stringify(response);
            }
            throw error;
        }
        return response;
    }
}

export default ApiResultHandler;
