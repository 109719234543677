import { generatePath } from 'react-router-dom';
import * as queryString from 'query-string';

class Routes {
    public static BASE_ROUTE = '';    
    public static UNAUTHORIZED = Routes.BASE_ROUTE + '/unauthorized';

    public static LOGIN_ROUTE = Routes.BASE_ROUTE + '/login';

    public static ERROR_SUMMARY_BASE = Routes.BASE_ROUTE + '/:section/error-summary';
    public static ERROR_SUMMARY_FORM = Routes.ERROR_SUMMARY_BASE + '/search';
    public static ERROR_SUMMARY_VIEW = Routes.ERROR_SUMMARY_BASE + '/view';

    public static RECORD_SEARCH_BASE = Routes.BASE_ROUTE + '/:section/records';
    public static RECORD_SEARCH_FORM = Routes.RECORD_SEARCH_BASE + '/search';
    public static RECORD_SEARCH_VIEW = Routes.RECORD_SEARCH_BASE + '/view';

    public static REPORTS = Routes.BASE_ROUTE + '/:section/reports';

    /**
     * Generated a url from a route and parameters.
     * @param route Route that may contain parameter placeholders.
     * @param params Object where property names equal the parameter placeholders in the route an the property value is what will be injected.
     */
    public static generate(
        route: string,
        params?: { [paramName: string]: string | number | boolean },
        query?: { [name: string]: any }) {

        let path = generatePath(route, params);

        // Add any query string variables to the route if passed
        if (query) {
            let q = queryString.stringify(query);
            if (q) {
                path += `?${q}`;
            }
        }

        return path;
    }
}

export default Routes;
