// @ts-nocheck
// All globals must be added to the custom_typings\index.d.ts file so typescript knows about them.
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'react-fast-compare';

const currency5 = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD', maximumFractionDigits: 5, minimumFractionDigits: 5 }).format;
const currency2 = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format;
const quantity0 = new Intl.NumberFormat(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format;
const quantity2 = new Intl.NumberFormat(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format;
const quantity5 = new Intl.NumberFormat(undefined, { maximumFractionDigits: 5, minimumFractionDigits: 5 }).format;

export default function addGlobals() {
    // tslint:disable-next-line:no-string-literal
    global['FORMAT'] = {
        currency5: (value: number) => currency5(roundTo(value, 5)),
        currency2: (value: number) => currency2(roundTo(value, 2)),
        quantity0: (value: number) => quantity0(roundTo(value, 0)),
        quantity2: (value: number) => quantity2(roundTo(value, 2)),
        quantity5: (value: number) => quantity5(roundTo(value, 5)),
    };

    // tslint:disable-next-line:no-string-literal
    global['tryParseInt'] = function (value: any, defaultValue: number) {
        var retValue: number = defaultValue;
        if (value != null) {
            if (!isNaN(value) && typeof value === 'string' && value !== '') {
                retValue = parseInt(value, 10);
            } else if (typeof value === 'number') {
                retValue = Math.floor(value);
            }
            if (!isNaN(retValue)) {
                return retValue;
            }
        }
        return defaultValue;
    };

    // tslint:disable-next-line:no-string-literal
    global['roundTo'] = function (num: number, digits: number) {
        digits = digits || 0;
        var multiplicator = Math.pow(10, digits);
        num = Math.round((num + 0.000001) * multiplicator) / multiplicator;
        return num;
    };

    // tslint:disable-next-line:no-string-literal
    global['zeroPad'] = function (val: any, digits: number) {
        return ('0'.repeat(digits) + val).substr(-digits, digits);
    };

    // tslint:disable-next-line:no-string-literal
    global['removeObjectProperties'] = function (object: any, names: string[]) {
        if (!object) { return object; }

        let clone = cloneDeep(object);

        let loop = (obj: any, keys: string[]) => {
            var index;
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    if (typeof obj[prop] === 'object') {
                        index = keys.indexOf(prop);
                        if (index > -1) {
                            delete obj[prop];
                        } else {
                            loop(obj[prop], keys);
                        }
                    } else {
                        index = keys.indexOf(prop);
                        if (index > -1) {
                            delete obj[prop];
                        }
                    }
                }
            }
        };

        loop(clone, names);

        return clone;
    };

    // tslint:disable-next-line:no-string-literal
    global['deepClone'] = function (obj: any) {
        if (!obj) { return obj; }
        return cloneDeep(obj);
    };

    // tslint:disable-next-line:no-string-literal
    global['deepEqual'] = function (a: any, b: any) {
        if ((!a && b) || (a && !b)) { return false; }
        if (!a && !b) { return true; }
        return isEqual(a, b);
    };
}
