export default class Guid {

    public static EmptyGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }

    public static NewGuid() {
        return Guid.s4() + Guid.s4() + '-' + Guid.s4() + '-' + Guid.s4() + '-' +
            Guid.s4() + '-' + Guid.s4() + Guid.s4() + Guid.s4();
    }

    private static s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
     }
}