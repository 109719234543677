import * as React from 'react';
import Select from 'react-select';

interface IpopSelectProps {
    onSelect?: (value: any) => void;
    onChange?: (value: any) => void;
    style?: React.CSSProperties;
    value?: any;
    id?: string;
    disabled?: boolean;
    customClear?: boolean;
    includeInit?: boolean;
    readOnly?: boolean;
    options?: { value?: string | boolean, label: React.ReactNode, options?: { label: React.ReactNode, value: string }[] }[];
    className?: string;
    placeholder?: string;
    tabIndex?: number;
    autoFocus?: boolean;
    defaultValue?: string;
    loading?: boolean;
    size?: 'small';
    zIndex?: number | 'auto' | '-moz-initial' | 'inherit' | 'initial' | 'revert' | 'unset' | undefined;
}

interface IpopSelectState {
    initOption: any;
}

// TODO: IpopSelet
// Control state of dropdown so we get desired behavior
// Tabbing when item is selected should select the item
// Tabbing when focused in dropdown should not move to next input

class IpopSelect extends React.Component<IpopSelectProps, IpopSelectState> {

    private _inputRef: Select | null;

    constructor(props: IpopSelectProps) {
        super(props);
        let initOpt = props?.options?.find(x => x.value === this.props.value) || null;

        this.state = {
            initOption: props.includeInit && initOpt == null && this.props.value ? 
                { value: this.props.value.toString(), label: this.props.value } : null,
        };
    }

    componentDidUpdate(prevProps: IpopSelectProps, prevState: IpopSelectState) {            
        const valueChanged = (prevProps.value == null && this.props.value != null) || prevProps.value !== this.props.value;

        if (this.props.includeInit && valueChanged) {
            let options = this.props.options || [];
            let initOpt = options.find(x => x.value === this.props.value) || null;
            this.setState({
                initOption: initOpt == null && this.props.value ?
                    { value: this.props.value.toString(), label: this.props.value }
                    :
                    null
            });
        }
    }

    render() {

        let disabled = this.props.disabled;
        let menuIsOpen = undefined;
        let className = this.props.className || '';
        let options = [];

        if (this.props.disabled || this.props.readOnly) {
            disabled = true;
            menuIsOpen = false;
        }

        if (className.length > 0) {
            className += ' ';
        }
        className += 'ipopSelect';

        if (this.props.customClear !== false) {
            options.push({ value: 'customClear', label: <React.Fragment>&nbsp;</React.Fragment> });
        }

        if (this.state.initOption) {
            options.push(this.state.initOption);
        }

        if (this.props.options) {
            options.push(...this.props.options);
        }

        let defaultVal = (options || []).find(o =>
            (o.value && o.value === this.props.value) || (o.value && o.value === this.props.defaultValue)
        );

        if (defaultVal == null) {
            try {
                let group =
                    (this.props.options || []).find(g =>
                        (g.options || []).find(o =>
                            (o.value && o.value === this.props.value) || (o.value && o.value === this.props.defaultValue)
                        )
                    );
                if (group) {
                    defaultVal = (group.options || []).find(o =>
                        (o.value && o.value === this.props.value) || (o.value && o.value === this.props.defaultValue)
                    );
                }

            } catch (error) {
                // pass
            }
        }

        return (
            <Select

                styles={{
                    container: (a, b) => ({
                        boxSizing: 'border-box',
                        margin: 0,
                        marginTop: 0,
                        fontVariant: 'tabular-nums',
                        listStyle: 'none',
                        width: '100%',
                        height: this.props.size === 'small' ? '25px' : '32px',
                        position: 'relative',
                        display: 'inline-block',
                        color: 'rgba(0,0,0,0.65) !important',
                        fontSize: this.props.size === 'small' ? '11px' : '14px',
                        backgroundColor: '#fff',
                        backgroundImage: 'none',
                        border: '1px solid #d9d9d9',
                        borderRadius: '4px',
                        lineHeight: 1.5,
                        transition: 'all 0.3s',
                        zIndex: this.props.zIndex || 'auto',
                        ...this.props.style
                    }),
                    control: (a, b) => ({
                        height: this.props.size === 'small' ? '25px' : '32px',
                        display: 'flex'
                    }),
                    singleValue: (a, b) => ({
                        ...a,
                        color: 'rgba(0,0,0,0.65)'
                    })
                }}
                placeholder={this.props.placeholder || null}
                tabIndex={this.props.tabIndex ? this.props.tabIndex.toString() : undefined}
                value={defaultVal || null}
                isDisabled={disabled}
                id={this.props.id}
                isClearable={false}
                menuIsOpen={menuIsOpen}
                onChange={this.onSelect}
                ref={r => this._inputRef = r}
                onKeyDown={this.onInputKeyDown}
                className={className}
                options={options}
                isLoading={this.props.loading}
                autoFocus={this.props.autoFocus}
            />

        );
    }

    focus = () => {
        if (this._inputRef) {
            this._inputRef.focus();
        }
    }

    onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // If delete key pressed, promote undefined as the changed value (like clearing the field)
        if (e.keyCode === 46 && (this.props.customClear !== false) && this.props.disabled !== true) {
            if (this.props.onSelect) {
                this.props.onSelect(undefined as any); // , undefined as any);
            }
            if (this.props.onChange) {
                this.props.onChange(undefined as any); // , undefined as any);
            }
        }

        // // Pass to parent if they included the prop
        // if (this.props.onInputKeyDown) {
        //     this.props.onInputKeyDown(e);
        // }
    }

    onSelect = (e: any, opt: any) => {
        if (e.value === 'customClear') {
            if (this.props.onSelect) {
                this.props.onSelect(undefined as any); // , undefined as any);
            }
            if (this.props.onChange) {
                this.props.onChange(undefined as any); // , undefined as any);
            }
            return;
        }

        console.log(this.props);
        if (this.props.onSelect) {
            this.props.onSelect(e.value);
        }
        if (this.props.onChange) {
            this.props.onChange(e.value);
        }
    }
}

export default IpopSelect;
