import * as React from 'react';
import AuthenticationAppDTO from 'src/models/AuthenticationAppDTO';
import {
    Col,
    Form,
    Row,
    Button
} from 'antd';
import { FormComponentProps, FormCreateOption } from 'antd/lib/form';
import IpopSelect from 'src/shared/form-controls/IpopSelect';
import IpopInput from 'src/shared/form-controls/IpopInput';

const FormItem = Form.Item;

interface AddDeviceFormProps extends FormComponentProps {
    authApps: AuthenticationAppDTO[];
    qrUri: string;
    deviceKey: string;
    onSubmit: () => void;
}

interface LoginFormState {
    selectedAuthApp: AuthenticationAppDTO | null;
}

const formCreateOptions: FormCreateOption<AddDeviceFormProps> = {
    onFieldsChange(props: AddDeviceFormProps, fields: any[], allFields: any) {
        // pass
    }
};

class AddDeviceForm extends React.Component<AddDeviceFormProps & FormComponentProps, LoginFormState> {
    constructor(props: AddDeviceFormProps & FormComponentProps) {
        super(props);

        this.state = {
            selectedAuthApp: null,
        };
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        let recommendedOptions: AuthenticationAppDTO[] = [];
        let otherOptions: AuthenticationAppDTO[] = [];

        this.props.authApps.forEach(a => {
            if (a.isRecommended) {
                recommendedOptions.push(a);
            } else {
                otherOptions.push(a);
            }
        });

        return (
            <React.Fragment>
                <p>
                    This setup information is only valid for the next 10 minutes.
                    Please complete the setup and verify your device in that time.
                        </p>
                <Form layout="horizontal" className="form-flex label-180">
                    <FormItem label="Device Name">
                        {getFieldDecorator('deviceName', {
                            rules: [
                                { required: true, message: 'Required' },
                                { max: 100, message: 'Device Name must be less than 100 characters in length'}
                            ]
                        })(<IpopInput autoFocus={true} />)}
                    </FormItem>
                    <FormItem label="Authenticator App">
                        {getFieldDecorator('authAppCode', {
                            initialValue: undefined,
                            rules: [{ required: true, message: 'Required' }]
                        })(
                            <IpopSelect
                                onSelect={(e: string) => this.setState({ selectedAuthApp: this.props.authApps.find(a => a.code === e) || null })}
                                options={[
                                    {
                                        label: 'Recommended',
                                        options: recommendedOptions.map(a => ({ label: a.name || '', value: a.code || '' }))
                                    },
                                    {
                                        label: 'Other',
                                        options: otherOptions.map(a => ({ label: a.name || '', value: a.code || '' }))
                                    }
                                ]}
                            />
                        )}
                    </FormItem>
                    {
                        this.state.selectedAuthApp && this.state.selectedAuthApp.isGenericEntry ? (
                            <FormItem
                                label="Authenticator App Name"
                                help="Any authenticator that utilizes the TOTP algorithm is supported."                                
                            >
                                {getFieldDecorator('authAppName', {
                                    rules: [
                                        { required: true, message: 'Required' },
                                        { max: 50, message: 'Authenticator App Name must be less than 50 characters in length'}
                                    ]
                                })(<IpopInput />)}
                            </FormItem>
                        ) : null
                    }
                    <Button style={{ display: 'none' }} htmlType="submit" onClick={this.props.onSubmit} />
                </Form>
                <Row type="flex">
                    <Col>
                        <img id="QRCode" alt="QR Code" src={this.props.qrUri} />
                    </Col>
                    <Col>
                        <span id="key">Key: {this.props.deviceKey}</span>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default Form.create<AddDeviceFormProps>(formCreateOptions)(AddDeviceForm);