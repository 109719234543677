import UserSecurityDTO from './UserSecurityDTO';
import SecurityRole from 'src/consts/SecurityRole';

export default class UserSecurity {
    public readonly firstName: string | null;
    public readonly lastName: string | null;
    public readonly userId: string | null;
    public readonly userName: string | null;
    public readonly facilityName: string | null;
    public readonly facilityId: string | null;
    public readonly emulated: boolean;

    private readonly permissions: ReadonlyArray<number>;
    private readonly roles: ReadonlyArray<number>;

    constructor(userinfo: UserSecurityDTO) {
        this.firstName = userinfo.firstName;
        this.lastName = userinfo.lastName;
        this.userId = userinfo.userId;
        this.userName = userinfo.userName;

        this.facilityId = userinfo.facilityId;
        this.facilityName = userinfo.facilityName;
        this.emulated = userinfo.emulated;

        this.permissions = Object.freeze(userinfo.permissions);
        this.roles = Object.freeze(userinfo.roles);
    }

    hasPermission(permission: number) {
        return this.permissions.indexOf(permission) >= 0;
    }

    getPermissions() {
        return this.permissions.slice(0);
    }

    hasRole(role: number) {
        return this.roles.indexOf(role) >= 0;
    }

    getRoles() {
        return this.roles.slice(0);
    }

    userSection(): string {
        return this.roles.includes(SecurityRole.SPONSOR) ? 'sponsor' : 'participant';
    }
}
