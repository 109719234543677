import Guid from './Guid';

class DownloadContext {
    public token: string;
    public attempts: number;
    public callback: Function;
    public iframe: HTMLIFrameElement;
    public error: string | null;
    public isLoaded: boolean;
}

export default class FileDownload {

    public static download(url: string, callback: Function): void {
        let that = this;
        let iframe = document.createElement('iframe');
        let context: DownloadContext = {
            token : Guid.NewGuid(),
            attempts : 600, // Oct 2020: 5 minutes (issues with reports not downloading)
            callback : callback,
            iframe : iframe,
            error : null,
            isLoaded : false
        };
        iframe.id = context.token;
        iframe.style.display = 'none';
        // iframe.style.height = '300px';
        // iframe.style.width = '400px';
        document.body.appendChild(iframe);

        iframe.onload = function(e: Event) {
            try {
                 let doc = context.iframe.contentDocument ||
                    (context.iframe.contentWindow != null ? context.iframe.contentWindow.document : window.document);
                 let error = JSON.parse(doc.body.innerText);
                 context.error = !!error ? error.message : null;
                 context.isLoaded = true;
            } catch {
                // Intentionally Blank
            }
         };

        if (url.indexOf('?') < 0) {
            url += '?';
        } else {
            url += '&';
        }

        iframe.src = url + 'downloadToken=' + context.token;
        // document.body.insertAdjacentHTML('beforeend', `<iframe id="${context.token}" onload="alert(\'here\')" src="${iframe.src}"></iframe>`);
        that.checkCookie(context);

    }

    public static downloadBase64StringAsExcel(fileName: string, base64String: string) {
        var downloader = document.createElement('a');
        downloader.setAttribute('href', 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + base64String);
        downloader.setAttribute('download', fileName);
        downloader.click();
    }

    public static downloadBase64StringAsFile(fileName: string, contentType: string, base64String: string) {
        var downloader = document.createElement('a');
        downloader.setAttribute('href', 'data:' + contentType + ';base64,' + base64String);
        downloader.setAttribute('download', fileName);
        downloader.click();
    }

    private static getCookie(name: string | null): string | null {
        let parts: string[] = document.cookie.split(name + '=');
        let val: string | null = null;
        if (parts.length === 2) {
            let p1: string = parts.pop() || '';
            val = p1.split(';').shift() || null;
        }
        return val;
    }

    private static expireCookie(name: string | null): void {
        if (name == null) { return; }
        document.cookie = encodeURIComponent(name) + '=deleted; expires=' + new Date(0).toUTCString();
    }

//    private setFormToken(): string {
//        var guid:string = Guid.NewGuid();
//        (document.getElementById('downloadToken')as any).value = guid;
//        return guid;
// }

    // Prevents double-submits by waiting for a cookie from the server.
    // private blockResubmit() {
    //    let that = this;
    //    let downloadToken = that.setFormToken();

    //    that.downloadTimer = window.setInterval(function () {
    //        let token = that.getCookie('downloadToken');

    //        if ((token == downloadToken) || (that.attempts == 0)) {
    //            that.unblockSubmit();
    //        }

    //        that.attempts--;
    //    }, 1000);
    // }

    // private unblockSubmit() {
    //    let that = this;
    //    window.clearInterval(that.downloadTimer);
    //    that.expireCookie('downloadToken');
    //    that.attempts = 30;
    // }

    private static checkCookie(context: DownloadContext) {
        let that = this;
        let value = that.getCookie(context.token);
        if (value || context.isLoaded  || context.attempts === 0) {
            if (context.attempts === 0) {
                context.error = 'Download timed out.';
            }
            that.expireCookie(context.token);
            document.body.removeChild(context.iframe);
            context.callback(context.error);
        } else {
            window.setTimeout(
                function () {
                    context.attempts--;
                    that.checkCookie(context);
                },
                500);
        }
    }
}
