import * as React from 'react';
import Input, { InputProps } from 'antd/lib/input';

interface IpopInputProps extends InputProps {
    debounceTime?: number;
}

interface IpopInputState {
    value?: string | number | readonly string[];
    lastEvent?: React.ChangeEvent<HTMLInputElement>;
    id?: string;
}

class IpopInput extends React.Component<IpopInputProps, IpopInputState> {
    private timeout: any = undefined;
    private timeoutCleared: boolean = true;
    private inputRef: Input | null;

    constructor(props: IpopInputProps) {
        super(props);

        this.state = {
            value: props.value,
            lastEvent: undefined,
            id: props.id
        };
    }

    componentDidUpdate(prevProps: IpopInputProps, prevState: IpopInputState) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }

    render() {
        let myProps = this.props;
        return (
            <Input
                {...myProps}
                onChange={this.onChange}
                value={this.state.value}
                ref={r => this.inputRef = r}
                onPressEnter={this.onEnter}
                onBlur={this.onBlur}
                id={this.props.id}
            />
        );
    }

    public focus = () => {
        if (this.inputRef) {
            this.inputRef.focus();
        }
    }

    private onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!this.timeoutCleared) {
            if (this.timeout) {
                clearInterval(this.timeout);
                this.timeoutCleared = true;
            }
            this.bubbleOnChange();
        }

        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }

    private onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.timeout) {
            clearInterval(this.timeout);
            this.timeoutCleared = true;
        }

        this.bubbleOnChange();
        if (this.props.onPressEnter) {
            this.props.onPressEnter(e);
        }
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        this.setState({ value: e.target.value, lastEvent: e });

        if (this.timeout) {
            clearInterval(this.timeout);
            this.timeoutCleared = true;
        }

        this.timeout = setTimeout(
            () => { this.bubbleOnChange(); },
            this.props.debounceTime || 500);
        this.timeoutCleared = false;
    }

    private bubbleOnChange = () => {
        if (this.props.onChange && this.state.lastEvent) {
            this.props.onChange(this.state.lastEvent);
        }
    }
}

export default IpopInput;
