import * as React from 'react';
import { Form, Button } from 'antd';
import IpopInput from 'src/shared/form-controls/IpopInput';
import { FormComponentProps, FormCreateOption } from 'antd/lib/form';

const FormItem = Form.Item;

interface MfaChallengeFormProps extends FormComponentProps {
    onSubmit: () => void;
    submitText?: string;
}

interface LoginFormState {

}

const formCreateOptions: FormCreateOption<MfaChallengeFormProps> = {
    onFieldsChange(props: MfaChallengeFormProps, fields: any[], allFields: any) {
        // pass
    }
};

class MfaChallengeForm extends React.Component<MfaChallengeFormProps & FormComponentProps, LoginFormState> {
    constructor(props: MfaChallengeFormProps & FormComponentProps) {
        super(props);

        this.state = {

        };
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="horizontal" className="form-flex label-140">
                <FormItem label="Enter Security Code" help="Run the authenticator app on your device to get a six-digit code.">
                    {getFieldDecorator('mfaChallenge', {
                        rules: [{ required: true, message: 'Code required.' }]
                    })(<IpopInput autoFocus={true} type="number" />)}
                </FormItem>

                {this.props.submitText ? (
                    <FormItem label="&nbsp;" colon={false}>
                        <Button
                            onClick={this.props.onSubmit}
                            type="primary"
                            htmlType="submit">
                            {this.props.submitText}
                        </Button>
                    </FormItem>
                ) : (
                        <Button
                            style={{ display: 'none' }}
                            onClick={this.props.onSubmit}
                            type="primary"
                            htmlType="submit" />
                    )}

            </Form>
        );
    }
}

export default Form.create<MfaChallengeFormProps>(formCreateOptions)(MfaChallengeForm);