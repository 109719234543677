
import BaseApi from './BaseApi';
import AddMfaDeviceDTO from '../models/AddMfaDeviceDTO';
import GenericResponseDTO from '../models/GenericResponseDTO';
import UserSecurityDTO from '../models/UserSecurityDTO';
import FacilityLookupDTO from '../models/FacilityLookupDTO';

export class AccountApiService extends BaseApi {

    // get: api/Account/Logout
    public logout(): Promise<GenericResponseDTO> {
        let url = `api/Account/Logout`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GenericResponseDTO>(null, 'get', url, true, false);
    }

    // get: api/Account/GetCurrentUser
    public getCurrentUser(): Promise<UserSecurityDTO> {
        let url = `api/Account/GetCurrentUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, UserSecurityDTO>(null, 'get', url, true, false);
    }

    // post: api/Account/AddMfaDevice
    public addMfaDevice(dto: AddMfaDeviceDTO): Promise<AddMfaDeviceDTO> {
        let url = `api/Account/AddMfaDevice`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, AddMfaDeviceDTO>(dto, 'post', url, true, false);
    }

    // post: api/Account/VerifyMfaDevice
    public verifyMfaDevice(dto: AddMfaDeviceDTO): Promise<FacilityLookupDTO[]> {
        let url = `api/Account/VerifyMfaDevice`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, FacilityLookupDTO[]>(dto, 'post', url, true, false);
    }
}
var service = new AccountApiService();
export default service;
